import * as auth0 from '@auth0/auth0-vue';
import { unref, watchEffect } from 'vue';

function watchEffectOnceAsync<T>(watcher: () => T) {
  return new Promise<void>((resolve) => {
    watchEffectOnce(watcher, resolve);
  });
}

/**
 * @ignore
 * Run watchEffect untill the watcher returns true, then stop the watch.
 * Once it returns true, it will call the provided function.
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function watchEffectOnce<T>(watcher: () => T, fn: Function) {
  const stopWatch = watchEffect(() => {
    if (watcher()) {
      fn();
      stopWatch();
    }
  });
}

export const useAuth = () => {
  const auth = auth0.useAuth0();

  const guardHandler = async () => {
    const fn = async () => {
      if (unref(auth.isAuthenticated)) {
        return true;
      }
      return false;
    };
    if (!unref(auth.isLoading)) {
      return fn();
    }
    await watchEffectOnceAsync(() => !unref(auth.isLoading));
    return fn();
  };

  return {
    auth,
    guardHandler,
  };
};
