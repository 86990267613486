<template>
  <div class="cta-signup">
    <div class="cta-signup__inner container" v-observe="onObserve">
      <svg ref="graphics" class="cta-signup__graphics" viewBox="0 0.8 1488 486.5" fill="none">
        <mask id="cta-mask">
          <path d="M0 0H1488V488H0V0Z" fill="white" />
          <path ref="leftTop" d="M0 51C0 22.8335 22.8335 0 51 0H0V51Z" fill="black" />
          <path ref="rightBottom" d="M1437 488C1465.17 488 1488 465.167 1488 437V488H1437Z" fill="black" />
          <path
            ref="rightTop"
            d="M996 141V51C996 22.8335 973.167 0 945 0H1488V243C1488 214.833 1465.17 192 1437 192H1047C1018.83 192 996 169.167 996 141Z"
            fill="black"
          />
          <path
            ref="leftBottom"
            d="M996 435V437C996 465.167 1018.83 488 1047 488H0V333C0 361.167 22.8335 384 51 384H945C973.167 384 996 406.833 996 435Z"
            fill="black"
          />
        </mask>
        <path d="M0 0H1488V488H0V0Z" fill="#DFC8E5" mask="url(#cta-mask)" />
      </svg>
      <div class="cta-signup__block is-big">
        <h3 ref="title" class="cta-signup__title">
          Switch to New Zealand’s <br />
          only <strong>sustainable</strong> Fibre <br />
          internet today and get up to <br />
          6 months <strong>half price*</strong>
        </h3>
      </div>
      <div ref="button" class="cta-signup__block is-small">
        <ui-button label="Sign up" tag="app-link" to="/plans/#plans" class="cta-signup__button" />
      </div>
    </div>
    <responsive-image image-url="bg/sunset" alt="Environmentally Friendly Internet Provider" class="cta-signup__img" />
  </div>
</template>
<script lang="ts" setup>
import UiButton from '@/components/global/ui/UiButton.vue';
import { onMounted, ref } from 'vue';
import { useNuxtApp } from '#imports';
import { useMemberSignup } from '~/stores/signup';
import ResponsiveImage from '~/components/global/ui/ResponsiveImage.vue';

const graphics = ref(null);
const rightTop = ref(null);
const rightBottom = ref(null);
const leftTop = ref(null);
const leftBottom = ref(null);
const title = ref(null);
const button = ref(null);

let tl: gsap.core.Timeline | null = null;

const signup = useMemberSignup();

const onObserve = () => {
  if (tl) {
    tl.play();
  }
};

onMounted(() => {
  const { $gsap } = useNuxtApp();

  tl = $gsap.timeline({ paused: true });

  $gsap.set(graphics.value, { scale: 0, transformOrigin: '50% 50%' });
  $gsap.set(rightTop.value, { scale: 0.05, transformOrigin: '100% 0%' });
  $gsap.set(rightBottom.value, { scaleX: 0.05, transformOrigin: '100% 50%' });
  $gsap.set(leftTop.value, { scale: 0.05, transformOrigin: '0% 0%' });
  $gsap.set(leftBottom.value, { scale: 0.05, transformOrigin: '0% 100%' });
  $gsap.set(title.value, { y: 40, opacity: 0 });
  $gsap.set(button.value, { y: 40, opacity: 0 });

  tl.to(graphics.value, { scale: 1, opacity: 1, duration: 1, ease: 'power4.out' }, 0);
  tl.to(rightTop.value, { scale: 1, duration: 2.5, ease: 'power3.out' }, 0.1);
  tl.to(rightBottom.value, { scaleX: 1, duration: 2.5, ease: 'power3.out' }, 0.1);
  tl.to(leftTop.value, { scale: 1, duration: 2, ease: 'power4.out' }, 0.2);
  tl.to(leftBottom.value, { scale: 1, duration: 2, ease: 'power4.out' }, 0.2);
  tl.to(title.value, { y: 0, opacity: 1, duration: 1, ease: 'power3.out' }, 0.5);
  tl.to(button.value, { y: 0, opacity: 1, duration: 1, ease: 'power3.out' }, 0.8);
});
</script>

<style lang="scss" scoped>
.cta-signup {
  $self: &;
  padding: calc-clamp($mobile, $desktop, 80, 250) 0;
  position: relative;

  &__img {
    &,
    :deep(img) {
      @include cover;
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: end;

    @include mobile {
      grid-template-columns: 1fr;
      justify-content: center;
      text-align: center;
      background: var(--dark-pink);
      border-radius: 3vw;
      padding: calc-clamp($mobile, $tablet, 26, 40);
      //max-width: calc-clamp($mobile, $tablet, 330, 420);
    }
  }

  &__graphics {
    position: absolute;
    width: 100%;
    inset: 0;
    margin: auto;

    * {
      transform-box: view-box;
    }

    @include mobile {
      display: none;
    }
  }

  &__block {
    position: relative;

    small {
      font-size: 0.7em;
    }

    &.is-big {
      @include tablet {
        top: -3vw;
        padding-left: 4vw;
      }

      @include desktop {
        top: rem(-32);
        padding-left: rem(45);
      }
    }

    &.is-small {
      @include mobile {
        margin-top: calc-clamp($mobile, $tablet, 20, 25);
      }

      @include tablet {
        bottom: -5vw;
        padding-right: 4.65vw;
      }

      @include desktop {
        bottom: rem(-47);
        padding-right: rem(35);
      }
    }
  }

  ::v-deep(#{$self}__button) {
    @include desktop {
      font-size: rem(25);
      height: rem(60);
      padding-inline: rem(65);
    }
  }

  &__title {
    line-height: 1;
    font-size: calc-clamp($mobile, $tablet, 26, 40);
    @include mobile {
      br {
        display: none;
      }
    }

    @include tablet {
      font-size: 3.4vw;
    }

    @include desktop {
      font-size: rem(36);
    }
    @include widescreen {
      font-size: rem(40);
    }

    :deep(sup) {
      display: inline-block;
      vertical-align: inherit;
      transform: translateY(-50%);
      font-size: 0.7em;
    }
  }
}
</style>
