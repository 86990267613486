import type { MobileProductsList } from '@/types/mobile-products';
import { MobilePromoType, MobileTermType } from '@/types/mobile-products';
import { useRuntimeConfig } from 'nuxt/app';

const mobileProductsList = (): MobileProductsList => {
  const inProduction = useRuntimeConfig().public.stripeProducts === 'live';

  return {
    promo: [
      {
        name: 'signup',
        title: '50% off discount',
        description: 'Bring your number for first 3 months half price*',
        benefit: 'Bring your number for first 3 months half price*',
        term: MobileTermType.SIGNUP,
        type: MobilePromoType.KEEP_YOUR_NUMBER,
        summaryDescription: 'You will pay this for your first 3 months',
      },
      {
        name: 'fnf',
        title: 'Friends & Family 20% Off Discount',
        description: 'Zeronet friends and family enjoy 20% off mobile',
        benefit: 'Save 20% every month',
        term: MobileTermType.FNF,
        type: MobilePromoType.ALL,
        summaryDescription: 'You will pay this each month',
      },
    ],
    plans: [
      {
        id: 1,
        planKey: 'mobile-starter',
        name: 'Mobile Starter',
        isMostPopular: true,
        stripeCode: inProduction ? 'prod_R8PHqupQSihRRE' : '',
        stripePriceCode: inProduction ? 'price_1QG8SSLyen3md8eTCUVNAV3w' : 'price_1QAGvtLyen3md8eTTvf31TmM',
        price: 3000,
        features: {
          speed: 'Up to 3Mbps Unlimited Data',
          speedRotation: 0,
          messagesAndCalls: 'Unlimited SMS/MMS + calls to NZ/AU',
          videoQuality: 'SD (480p) Video',
          streaming: 'Standard Quality Streaming',
        },
        summaryFeatures: [
          'Truly Unlimited Data up to 3Mbps speeds',
          'Includes Unimited NZ & AU calling, SMS and MMS',
          'WiFi Calling and Hotspotting',
        ],
        promo: {
          signup: {
            price: 1500,
            discount: -1500,
            stripePriceCode: '',
          },
          fnf: {
            price: 2400,
            discount: -600,
            stripePriceCode: '',
          },
        },
      },
      {
        id: 2,
        name: 'Mobile Essentials',
        planKey: 'mobile-essentials',
        stripeCode: inProduction ? 'prod_R8PbOGdNXfplRC' : '',
        stripePriceCode: inProduction ? 'price_1QG8mgLyen3md8eTAZMYu2ld' : 'price_1QAH2xLyen3md8eT0sw05Eb3',
        price: 5000,
        features: {
          speed: 'Up to 10Mbps Unlimited Data',
          speedRotation: 45,
          messagesAndCalls: 'Unlimited SMS/MMS + calls to NZ/AU',
          videoQuality: 'HD (1080p) Video',
          streaming: 'High Quality Streaming',
        },
        summaryFeatures: [
          'Truly Unlimited Data up to 10Mbps speeds',
          'Includes Unimited NZ & AU calling, SMS and MMS',
          'WiFi Calling and Hotspotting',
        ],
        promo: {
          signup: {
            price: 2500,
            discount: -2500,
            stripePriceCode: '',
          },
          fnf: {
            price: 4000,
            discount: -1000,
            stripePriceCode: '',
          },
        },
      },
      {
        id: 3,
        name: 'Mobile Max',
        planKey: 'mobile-max',
        stripeCode: inProduction ? 'prod_R8PdYLWB0dlJ3I' : '',
        stripePriceCode: inProduction ? 'price_1QG8ntLyen3md8eTGsvBNKj8' : 'price_1Pn84vLyen3md8eTkSNo4FIF',
        price: 8000,
        features: {
          speed: 'Max speed Unlimited Data',
          speedRotation: 90,
          messagesAndCalls: 'Unlimited SMS/MMS + calls to NZ/AU',
          videoQuality: '4K Ultra HD Video',
          streaming: 'Studio Quality Streaming',
        },
        summaryFeatures: ['Max speed unlimited data', 'Includes Unimited NZ & AU calling, SMS and MMS', 'WiFi Calling and Hotspotting'],
        promo: {
          signup: {
            price: 4000,
            discount: -4000,
            stripePriceCode: '',
          },
          fnf: {
            price: 6400,
            discount: -1600,
            stripePriceCode: '',
          },
        },
      },
    ],
    setup: {
      id: 1,
      name: 'Setup Fee',
      price: 499,
      stripeCode: inProduction ? '' : '',
      stripePriceCode: inProduction ? 'price_1QG8dtLyen3md8eTbDGnbPKM' : 'price_1QG8gLLyen3md8eTBzsyLCTP',
      immediateCharge: true,
      promo: {
        fnf: {
          price: 50,
          stripePriceCode: inProduction ? 'price_1QG8dtLyen3md8eTrOnNG0PE' : 'price_1QPVaZLyen3md8eT02fSI42W',
        },
      },
    },
    delivery: {
      id: 1,
      name: 'Delivery Fee',
      price: 1500,
      stripeCode: inProduction ? '' : '',
      stripePriceCode: inProduction ? 'price_1QSULBLyen3md8eT7uu6waPX' : 'price_1NzRksLyen3md8eTRXI2BXne',
      immediateCharge: true,
      promo: {
        fnf: {
          price: 50,
          stripePriceCode: inProduction ? 'price_1QG8YaLyen3md8eTG4tnFGFc' : 'price_1QG8akLyen3md8eTfnbpDmVK',
        },
      },
    },
    discounts: {
      referralDiscount: {
        name: 'Referral Discount',
        title: 'Refer-a-friend Discount 50% off',
        discount: -0.5, // 50% off
      },
      abandonedCartDiscount: {
        name: 'Abandoned Cart Discount',
        title: 'Abandoned Cart Discount 50% off',
        discount: 0.5, // 50% off
      },
      // signupDiscount: {
      //   name: 'Signup Discount',
      //   title: '',
      //   discount: 0,
      // },
    },
  };
};

export default mobileProductsList;
