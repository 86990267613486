import { v4 as uuidv4 } from 'uuid';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { handleError } from '~/utils/errors';
import mobileProductsList from '~/lib/mobile-products';
import type { LooseObject, Nullable } from '@/types/generic';
import type { MobileDiscount, MobilePlan, MobilePlanPromo, MobilePromo, MobileSetUp } from '~/types/mobile-products';
import { MobilePromoType, MobileTermType } from '~/types/mobile-products';
import { roundTo } from 'round-to';
import { filterPhoneNumber } from '~/utils/phone';

export interface MobileSignupAddress {
  id: string | number;
  full_address: string;
}

export interface MobileOrderCustomerAddress {
  address: string;
  address_id: number;
  billing_address: string;
  billing_address_id?: number;
  delivery_address: string;
  delivery_address_id?: number;
  order_token: string;
  metadata?: MobileOrderCustomerMetadata;
  connection?: MobileOrderCustomerConnection;
}

export type MobileOrderCustomerConnection = LooseObject;

export type MobileOrderCustomerMetadata = LooseObject;

export interface MobileSignupDetails extends LooseObject {
  address?: MobileSignupAddress;
  plan?: MobilePlan;
  simType: SimType;
  simName: string;
  keepNumber: boolean | null;
  promo?: string;
  referrer?: string;
  referralCode?: string;
  commEmail?: string;
}

export interface MobileOrderTotals {
  oneOff: number;
  oneOffDiscount?: number;
  monthly: number;
  monthlyFull: number;
  total: number;
}

export interface MobileSignupPurchaseVars {
  uid: string;
  oneOffItems: string[];
  monthlyTotal: number;
  monthlyTotalTax: number;
  oneOffTotal: number;
}

export interface MobileSignupState {
  steps: number;
  isLoading: boolean;
  currentStep: number;
  signupDetails: MobileSignupDetails;
  orderToken: string;
  paymentError: Nullable<string>;
  defaultPromo: string;
}

export enum SimType {
  ESIM = 'esim',
  PHYSICAL = 'physical',
}

export const STEP_MAPPING: Record<number, string> = {
  1: 'select-plan',
  2: 'account',
  3: 'connection-setup',
  4: 'order-summary',
};

const getSignupDetailsDefaults = (): MobileSignupDetails => ({
  plan: undefined,
  referrer: undefined,
  simType: SimType.ESIM,
  simName: '',
  keepNumber: null,
});

export const useMobileSignup = defineStore('signup-mobile', {
  persist: true,
  state: (): MobileSignupState => ({
    defaultPromo: 'signup',
    signupDetails: getSignupDetailsDefaults(),
    currentStep: 1,
    steps: Object.values(STEP_MAPPING).length,
    isLoading: false,
    orderToken: '',
    paymentError: null,
  }),
  getters: {
    currentStepSlug(state) {
      return STEP_MAPPING[state.currentStep];
    },
    hasNextStep(state) {
      return state.currentStep < state.steps;
    },
    hasPrevStep(state) {
      return state.currentStep > 1;
    },
    signupConnectionSetupValid(state) {
      return !!(state.signupDetails.simType && state.signupDetails.simName);
    },

    signupOrderSetup(state): MobileSetUp {
      const { setup } = mobileProductsList();

      if (state.signupDetails.promo && setup.promo[state.signupDetails.promo]) {
        return {
          ...setup,
          ...setup.promo[state.signupDetails.promo],
        };
      }

      return setup;
    },

    signupOrderDelivery(state): MobileSetUp {
      const { delivery } = mobileProductsList();

      if (state.signupDetails.promo && delivery.promo[state.signupDetails.promo]) {
        return {
          ...delivery,
          ...delivery.promo[state.signupDetails.promo],
        };
      }

      return delivery;
    },

    signupRequiresDelivery(state) {
      return state.signupDetails.simType === SimType.PHYSICAL;
    },

    signupRequiresSetupFee() {
      return true;
    },

    signupReferralDiscount(state) {
      if (!state.signupDetails.referralCode) {
        return null;
      }

      let total = 0;
      if (this.signupRequiresDelivery) {
        total += this.signupOrderDelivery.price;
      }
      if (this.signupRequiresSetupFee) {
        total += this.signupOrderSetup.price;
      }

      const { discounts } = mobileProductsList();
      if (state.signupDetails.referralCode.startsWith('cus_')) {
        // fixed discount
        if (discounts.referralDiscount.price) {
          return discounts.referralDiscount;
        }

        return {
          ...discounts.referralDiscount,
          price: total * discounts.referralDiscount.discount,
        } as MobileDiscount;
      }

      if (state.signupDetails.referralCode === 'MOB50OFF') {
        const discount = discounts.abandonedCartDiscount;

        return {
          ...discount,
          price: -total * discount.discount,
        } as MobileDiscount;
      }

      return null;
    },

    orderPriceIds(state) {
      const priceIds: string[] = [];

      if (state.signupDetails.plan) {
        if (this.planPromo && this.planPromo.stripePriceCode) {
          priceIds.push(this.planPromo.stripePriceCode);
        } else if (state.signupDetails.plan.stripePriceCode) {
          priceIds.push(state.signupDetails.plan.stripePriceCode);
        }
      }

      if (this.signupRequiresDelivery) {
        priceIds.push(this.signupOrderDelivery.stripePriceCode);
      }

      if (this.signupRequiresSetupFee) {
        priceIds.push(this.signupOrderSetup.stripePriceCode);
      }

      return priceIds.length ? priceIds : undefined;
    },

    purchaseVars(state): MobileSignupPurchaseVars {
      const vars: MobileSignupPurchaseVars = {
        uid: uuidv4(),
        oneOffItems: [],
        monthlyTotal: 0.0,
        monthlyTotalTax: 0.0,
        oneOffTotal: 0.0,
      };

      if (state.signupDetails.plan) {
        const price = state.signupDetails.plan.price;
        if (price) {
          vars.monthlyTotal += price / 100;
        }
      }

      vars.oneOffItems.push(this.signupOrderSetup.name);
      vars.oneOffTotal += this.signupOrderSetup.price / 100;

      vars.oneOffItems.push(this.signupOrderDelivery.name);
      vars.oneOffTotal += this.signupOrderDelivery.price / 100;

      vars.monthlyTotalTax = roundTo(vars.monthlyTotal * 0.15, 2); // GST
      vars.oneOffTotal = roundTo(vars.oneOffTotal, 2);

      return vars;
    },

    purchaseEventVars(state): LooseObject {
      return {
        value: this.purchaseVars.monthlyTotal,
        tax: this.purchaseVars.monthlyTotalTax,
        one_off_items: this.purchaseVars.oneOffItems.join(','),
        one_off_total: this.purchaseVars.oneOffTotal,
        currency: 'NZD',
        coupon: this.planPromo?.name || '',
        items: [
          {
            item_id: state.signupDetails.plan?.id,
            item_name: this.orderPlanName || '',
            coupon: this.planPromo?.name || '',
            price: this.purchaseVars.monthlyTotal,
            item_category: 'Mobile Plans',
            quantity: 1,
          },
        ],
      };
    },

    planPromo(state) {
      if (!state.signupDetails.promo || !state.signupDetails.plan?.promo[state.signupDetails.promo]) {
        return null;
      }

      const { promo } = mobileProductsList();
      const planPromo = promo.find((p) => p.name === state.signupDetails.promo) || null;
      const signupPlanPromo = state.signupDetails.plan?.promo[state.signupDetails.promo];

      // some promos can only be applied to the plan if the user is keeping their number or vice versa
      const applyPlanPromo =
        planPromo?.type === MobilePromoType.ALL ||
        (MobilePromoType.KEEP_YOUR_NUMBER && state.signupDetails.keepNumber) ||
        (planPromo?.type === MobilePromoType.NEW_NUMBER && !state.signupDetails.keepNumber);

      return applyPlanPromo ? ({ ...planPromo, ...signupPlanPromo } as MobilePlanPromo & MobilePromo) : null;
    },

    orderContract(): string {
      return '12';
    },

    orderSignupTerm(): number | string {
      return this.planPromo?.term || MobileTermType.SIGNUP; // default term
    },

    orderConnection(state): MobileOrderCustomerConnection {
      return {
        plan_key: state.signupDetails.plan?.planKey,
        plan: this.orderPlanName,
        sim_type: state.signupDetails.simType,
        sim_name: state.signupDetails.simName,
        keep_number: state.signupDetails.keepNumber,
        selected_number: state.signupDetails.selectedNumber,
        // we want the phone to be prefixed with 0
        phone: state.signupDetails.phone ? filterPhoneNumber(state.signupDetails.phone, true) : null,
      };
    },

    orderPlanName(state): string {
      return state.signupDetails.plan?.name || '-';
    },

    orderMetadata(state): MobileOrderCustomerMetadata {
      return {
        referral_code: state.signupDetails.referralCode || '',
        promo: this.planPromo?.name || '',
        signup_term: this.orderSignupTerm,
      };
    },

    orderAddress(state): MobileOrderCustomerAddress | undefined {
      if (!state.signupDetails.address) {
        return undefined;
      }

      return {
        address: state.signupDetails.address.full_address,
        address_id: state.signupDetails.address.id,
        billing_address: state.signupDetails.address.full_address,
        billing_address_id: state.signupDetails.address.id,
        delivery_address: state.signupDetails.address.full_address,
        delivery_address_id: state.signupDetails.address.id,
        comm_email: state.signupDetails.commEmail,
        order_token: this.orderToken,
        contract: this.orderContract,
        connection: this.orderConnection,
        metadata: this.orderMetadata,
      } as MobileOrderCustomerAddress;
    },

    orderTotal(state) {
      const totals: MobileOrderTotals = {
        oneOff: 0,
        oneOffDiscount: 0,
        monthly: 0,
        monthlyFull: 0,
        total: 0,
      };

      if (state.signupDetails.plan) {
        const price = state.signupDetails.plan.price;

        totals.monthly += price;
        totals.monthlyFull += price;

        if (this.planPromo) {
          totals.monthly = totals.monthly + this.planPromo.discount;
        }
      }

      if (this.signupRequiresDelivery) {
        totals.oneOff += this.signupOrderDelivery.price;
      }

      if (this.signupRequiresSetupFee) {
        totals.oneOff += this.signupOrderSetup.price;
      }

      if (this.signupReferralDiscount?.price) {
        totals.oneOff += this.signupReferralDiscount.price;
      }

      return totals;
    },
  },
  actions: {
    setStep(step: number) {
      this.currentStep = step;
    },
    nextStep() {
      if (this.currentStep < this.steps) {
        this.currentStep = this.currentStep + 1;
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep = this.currentStep - 1;
      }
    },
    saveDetails(details: LooseObject) {
      this.signupDetails = Object.assign({}, this.signupDetails, details);
    },
    reset() {
      this.signupDetails = getSignupDetailsDefaults();
      this.currentStep = 1;
      this.orderToken = '';
    },

    async confirmPayment() {
      try {
        this.paymentError = null;

        const accessToken = await this.$auth0.getAccessTokenSilently();

        const response = await this.$api.post(
          '/customer/mobile/payment/confirm',
          {
            address: this.orderAddress,
            price_ids: this.orderPriceIds,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (response.status === 200 || response.status === 201) {
          return true;
        }
      } catch (err: any) {
        this.paymentError = err?.data?.message || 'Oops! There’s been a problem with your card. Please check the details and try again.';
        handleError(err);
      }
      return false;
    },

    validateStep(step: number): boolean {
      const auth = useAuthStore();
      const hasPlan = !!this.signupDetails.plan;

      switch (step) {
        case 1:
          return true;
        case 2:
          return hasPlan;
        case 3:
          return hasPlan && auth.customerExists;
        case 4:
          return hasPlan && auth.customerExists && this.signupConnectionSetupValid;
      }

      return step === 1;
    },

    generateOrderToken(keepExisting = true) {
      if (keepExisting && this.orderToken) {
        return;
      }
      this.orderToken = uuidv4();
    },
  },
});
