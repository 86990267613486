export interface MobilePlan {
  id: string | number;
  name: string;
  planKey: string;
  price: number;
  signupPrice?: number;
  stripeCode: string;
  stripePriceCode?: string;
  isMostPopular?: boolean;
  summaryFeatures?: string[];
  features?: {
    speed?: string;
    speedRotation?: number;
    messagesAndCalls?: string;
    videoQuality?: string;
    streaming?: string;
  };
  promo: MobilePlanPromos;
}

export type MobilePlanPromos = Record<string, MobilePlanPromo>;

export interface MobilePlanPromo {
  price: number;
  discount: number;
  stripePriceCode: string;
}

export type MobileDeliveryPromos = Record<string, MobileDeliveryPromo>;

export interface MobileDeliveryPromo {
  price: number;
  stripePriceCode: string;
}

export type MobileSetUpPromos = Record<string, MobileSetUpPromo>;

export interface MobileSetUpPromo {
  price: number;
  stripePriceCode: string;
}

export interface MobileSetUp {
  id: string | number;
  name: string;
  price: number;
  stripeCode: string;
  stripePriceCode: string;
  immediateCharge: boolean;
  promo: MobileSetUpPromos;
}

export interface MobileDelivery {
  id: string | number;
  name: string;
  price: number;
  stripeCode: string;
  stripePriceCode: string;
  immediateCharge: boolean;
  promo: MobileDeliveryPromos;
}

export interface MobilePromo {
  name: string;
  title: string;
  benefit?: string;
  description?: string;
  funnelDescription?: string;
  funnelSubtitle?: string;
  term?: number;
  type: MobilePromoType;
  summaryDescription?: string;
}

export interface MobileDiscount {
  name: string;
  title: string;
  discount: number;
  price?: number;
}

export type MobileDiscounts = Record<string, MobileDiscount>;

export interface MobileProductsList {
  plans: MobilePlan[];
  promo: MobilePromo[];
  discounts: MobileDiscounts;
  setup: MobileSetUp;
  delivery: MobileDelivery;
}

export enum MobilePromoType {
  ALL = 'all',
  KEEP_YOUR_NUMBER = 'keep',
  NEW_NUMBER = 'new',
}

export enum MobileTermType {
  FNF = 4,
  SIGNUP = 3,
}
