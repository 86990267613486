import { default as _5_45month_45cart_45offerT2y4eHDBAaMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/5-month-cart-offer.vue?macro=true";
import { default as _6_45month_45half_45priceDoODDNIdSvMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/6-month-half-price.vue?macro=true";
import { default as about6CXfjiG5UdMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/about.vue?macro=true";
import { default as billingKfG9CrQQHQMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/account/billing.vue?macro=true";
import { default as indexUPff7oExA3Meta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/account/index.vue?macro=true";
import { default as profilehnd64EAIj2Meta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/account/profile.vue?macro=true";
import { default as accountzQgKJKn3jwMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/account.vue?macro=true";
import { default as authorizetu3LmGWg2HMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/authorize.vue?macro=true";
import { default as bbc_45promo9oV0uvXBrLMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/bbc-promo.vue?macro=true";
import { default as broadband_45friends_45and_45familyIDwCl7kTzYMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/broadband-friends-and-family.vue?macro=true";
import { default as chat8sXptvrvOAMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/chat.vue?macro=true";
import { default as compass_45embedVoKJOAzm2GMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/compass-embed.vue?macro=true";
import { default as compassLxKnM8K2axMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/compass.vue?macro=true";
import { default as contact8Rk1wQpBeLMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/contact.vue?macro=true";
import { default as coverageO208pqc8EZMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/coverage.vue?macro=true";
import { default as glimpR3cAOdX7yPMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/glimp.vue?macro=true";
import { default as hyperfibre9YBXAwyvM6Meta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/hyperfibre.vue?macro=true";
import { default as index3W94k9UOsAMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/index.vue?macro=true";
import { default as internet_45speed_45testTj3Ux5oHj1Meta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/internet-speed-test.vue?macro=true";
import { default as login9CTy9f2rnDMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/login.vue?macro=true";
import { default as logoutpbLIoHBVTpMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/logout.vue?macro=true";
import { default as mobile_45friends_45and_45familyfdkxyBywz2Meta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/mobile-friends-and-family.vue?macro=true";
import { default as indexmVsewNvUHQMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/index.vue?macro=true";
import { default as accountu1iVMhD8EOMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/account.vue?macro=true";
import { default as connection_45setupnuOO7u5nUsMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/connection-setup.vue?macro=true";
import { default as indexnK5MS0VFCvMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/index.vue?macro=true";
import { default as order_45summarylOYpe3L8gSMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/order-summary.vue?macro=true";
import { default as select_45plany0OtBEStvMMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/select-plan.vue?macro=true";
import { default as successQTQSn9ZiHkMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/success.vue?macro=true";
import { default as registerTtgfagqbbIMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register.vue?macro=true";
import { default as our_45plansdxPw1UQN0rMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/our-plans.vue?macro=true";
import { default as plans2B5PfoYUORMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/plans.vue?macro=true";
import { default as privacyvVYHxAD0oDMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/privacy.vue?macro=true";
import { default as promotionsmEs7qx5qAmMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/promotions.vue?macro=true";
import { default as refer_45a_45friend5nJCt7VrdOMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/refer-a-friend.vue?macro=true";
import { default as _1Xcg4d8N49DMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/register/1.vue?macro=true";
import { default as _2DbRCO8M1pTMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/register/2.vue?macro=true";
import { default as _3WJim8iYL4zMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/register/3.vue?macro=true";
import { default as _4bFHKfMrAvhMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/register/4.vue?macro=true";
import { default as _5AwfOyVLdDCMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/register/5.vue?macro=true";
import { default as indexq4od0RRLKCMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/register/index.vue?macro=true";
import { default as successcdb7vKwLorMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/register/success.vue?macro=true";
import { default as registerZ8onYWEfnbMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/register.vue?macro=true";
import { default as simply_45broadbandokbtUT0L89Meta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/simply-broadband.vue?macro=true";
import { default as studentcard_45exclusive_45offerMgxbgeMTHvMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/studentcard-exclusive-offer.vue?macro=true";
import { default as sustainabilityYrok6LISauMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/sustainability.vue?macro=true";
import { default as terms86gEmPa4hMMeta } from "/codebuild/output/src3998333396/src/zeronet-web/pages/terms.vue?macro=true";
export default [
  {
    name: "5-month-cart-offer",
    path: "/5-month-cart-offer",
    meta: _5_45month_45cart_45offerT2y4eHDBAaMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/5-month-cart-offer.vue")
  },
  {
    name: "6-month-half-price",
    path: "/6-month-half-price",
    meta: _6_45month_45half_45priceDoODDNIdSvMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/6-month-half-price.vue")
  },
  {
    name: "about",
    path: "/about",
    meta: about6CXfjiG5UdMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/about.vue")
  },
  {
    name: accountzQgKJKn3jwMeta?.name,
    path: "/account",
    meta: accountzQgKJKn3jwMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/account.vue"),
    children: [
  {
    name: "account-billing",
    path: "billing",
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/account/billing.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/account/index.vue")
  },
  {
    name: "account-profile",
    path: "profile",
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/account/profile.vue")
  }
]
  },
  {
    name: "authorize",
    path: "/authorize",
    meta: authorizetu3LmGWg2HMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/authorize.vue")
  },
  {
    name: "bbc-promo",
    path: "/bbc-promo",
    meta: bbc_45promo9oV0uvXBrLMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/bbc-promo.vue")
  },
  {
    name: "broadband-friends-and-family",
    path: "/broadband-friends-and-family",
    meta: broadband_45friends_45and_45familyIDwCl7kTzYMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/broadband-friends-and-family.vue")
  },
  {
    name: "chat",
    path: "/chat",
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/chat.vue")
  },
  {
    name: "compass-embed",
    path: "/compass-embed",
    meta: compass_45embedVoKJOAzm2GMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/compass-embed.vue")
  },
  {
    name: "compass",
    path: "/compass",
    meta: compassLxKnM8K2axMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/compass.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact8Rk1wQpBeLMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/contact.vue")
  },
  {
    name: "coverage",
    path: "/coverage",
    meta: coverageO208pqc8EZMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/coverage.vue")
  },
  {
    name: "glimp",
    path: "/glimp",
    meta: glimpR3cAOdX7yPMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/glimp.vue")
  },
  {
    name: "hyperfibre",
    path: "/hyperfibre",
    meta: hyperfibre9YBXAwyvM6Meta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/hyperfibre.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index3W94k9UOsAMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/index.vue")
  },
  {
    name: "internet-speed-test",
    path: "/internet-speed-test",
    meta: internet_45speed_45testTj3Ux5oHj1Meta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/internet-speed-test.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login9CTy9f2rnDMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutpbLIoHBVTpMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/logout.vue")
  },
  {
    name: "mobile-friends-and-family",
    path: "/mobile-friends-and-family",
    meta: mobile_45friends_45and_45familyfdkxyBywz2Meta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/mobile-friends-and-family.vue")
  },
  {
    name: "mobile",
    path: "/mobile",
    meta: indexmVsewNvUHQMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/index.vue")
  },
  {
    name: registerTtgfagqbbIMeta?.name,
    path: "/mobile/register",
    meta: registerTtgfagqbbIMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register.vue"),
    children: [
  {
    name: "mobile-register-account",
    path: "account",
    meta: accountu1iVMhD8EOMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/account.vue")
  },
  {
    name: "mobile-register-connection-setup",
    path: "connection-setup",
    meta: connection_45setupnuOO7u5nUsMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/connection-setup.vue")
  },
  {
    name: "mobile-register",
    path: "",
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/index.vue")
  },
  {
    name: "mobile-register-order-summary",
    path: "order-summary",
    meta: order_45summarylOYpe3L8gSMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/order-summary.vue")
  },
  {
    name: "mobile-register-select-plan",
    path: "select-plan",
    meta: select_45plany0OtBEStvMMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/select-plan.vue")
  },
  {
    name: "mobile-register-success",
    path: "success",
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/mobile/register/success.vue")
  }
]
  },
  {
    name: "our-plans",
    path: "/our-plans",
    meta: our_45plansdxPw1UQN0rMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/our-plans.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: plans2B5PfoYUORMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/plans.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyvVYHxAD0oDMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/privacy.vue")
  },
  {
    name: "promotions",
    path: "/promotions",
    meta: promotionsmEs7qx5qAmMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/promotions.vue")
  },
  {
    name: "refer-a-friend",
    path: "/refer-a-friend",
    meta: refer_45a_45friend5nJCt7VrdOMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/refer-a-friend.vue")
  },
  {
    name: registerZ8onYWEfnbMeta?.name,
    path: "/register",
    meta: registerZ8onYWEfnbMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/register.vue"),
    children: [
  {
    name: "register-1",
    path: "1",
    meta: _1Xcg4d8N49DMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/register/1.vue")
  },
  {
    name: "register-2",
    path: "2",
    meta: _2DbRCO8M1pTMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/register/2.vue")
  },
  {
    name: "register-3",
    path: "3",
    meta: _3WJim8iYL4zMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/register/3.vue")
  },
  {
    name: "register-4",
    path: "4",
    meta: _4bFHKfMrAvhMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/register/4.vue")
  },
  {
    name: "register-5",
    path: "5",
    meta: _5AwfOyVLdDCMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/register/5.vue")
  },
  {
    name: "register",
    path: "",
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/register/index.vue")
  },
  {
    name: "register-success",
    path: "success",
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/register/success.vue")
  }
]
  },
  {
    name: "simply-broadband",
    path: "/simply-broadband",
    meta: simply_45broadbandokbtUT0L89Meta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/simply-broadband.vue")
  },
  {
    name: "studentcard-exclusive-offer",
    path: "/studentcard-exclusive-offer",
    meta: studentcard_45exclusive_45offerMgxbgeMTHvMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/studentcard-exclusive-offer.vue")
  },
  {
    name: "sustainability",
    path: "/sustainability",
    meta: sustainabilityYrok6LISauMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/sustainability.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms86gEmPa4hMMeta || {},
    component: () => import("/codebuild/output/src3998333396/src/zeronet-web/pages/terms.vue")
  }
]