<template>
  <transition :name="`toast-${toast.position}`">
    <div v-if="toast.isActive" class="app-toast" :class="[`is-${toast.type}`, `is-${toast.position}`]">
      <div class="app-toast__inner container">
        <div class="app-toast__message" @click="toast.hide()" v-html="toast.message" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { useToastStore } from '~/stores/toast';
import { computed, watch } from '#imports';
import { delay } from '~/utils/delay';

const toast = useToastStore();
const isActive = computed(() => toast.isActive);

watch(isActive, async (val) => {
  if (val && !toast.persist) {
    await delay(5000);
    toast.hide();
  }
});
</script>

<style lang="scss" scoped>
.app-toast {
  position: fixed;
  left: 0;
  right: 0;
  z-index: map-get($z-index, toast);

  &.is-top {
    top: 0;
  }
  &.is-bottom {
    bottom: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__message {
    cursor: pointer;
    //pointer-events: none;
    margin: rem(20);
    background: var(--dark-pink);
    padding: 1em 2em;
    border-radius: 1em;
    text-align: center;
    line-height: 1.05;
    font-weight: 500;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
}

.toast-top-enter-active,
.toast-top-leave-active,
.toast-bottom-enter-active,
.toast-bottom-leave-active {
  @include transition;
}

.toast-top-enter-from,
.toast-top-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.toast-bottom-enter-from,
.toast-bottom-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
