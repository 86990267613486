export const filterPhoneNumber = (number: string, prefixWithZero = false) => {
  if (number.startsWith('+64')) {
    number = number.replace('+64', '0');
  }

  if (prefixWithZero && !number.startsWith('0')) {
    number = `0${number}`;
  }

  return number;
};

export const formatMobileNumber = (number: string, prefixWithZero = false) => {
  return filterPhoneNumber(number, prefixWithZero).replace(/(\d{3})(\d{3})(\d+)/, '$1 $2 $3');
};
