import type { $Fetch, FetchOptions, FetchResponse } from 'ofetch';
import { $fetch } from 'ofetch';
import type { LooseObject } from '~/types/generic';

export class Api {
  api: $Fetch;
  headers: LooseObject;

  constructor(config: FetchOptions, headers: LooseObject) {
    this.api = $fetch.create(config);
    this.headers = headers;
  }

  private getConfig(method: string, config?: any, body?: Record<string, any>): FetchOptions<'json', any> {
    if (!config) {
      return {
        method,
        headers: this.headers,
      };
    }
    const returnConfig = { method, headers: {}, ...config };
    returnConfig.headers = {
      ...returnConfig.headers,
      ...this.headers,
    };

    if (body) {
      returnConfig.body = body;
    }

    return returnConfig;
  }

  public get<T>(url: string, config?: FetchOptions): Promise<FetchResponse<T>> {
    return this.api.raw<T>(url, this.getConfig('GET', config));
  }

  public delete<T>(url: string, config?: FetchOptions): Promise<FetchResponse<T>> {
    return this.api.raw<T>(url, this.getConfig('DELETE', config));
  }

  public head<T>(url: string, config?: FetchOptions): Promise<FetchResponse<T>> {
    return this.api.raw<T>(url, this.getConfig('HEAD', config));
  }

  public post<T, B = Record<string, any>>(url: string, data?: B, config?: FetchOptions): Promise<FetchResponse<T>> {
    return this.api.raw<T>(url, this.getConfig('POST', config, data || {}));
  }

  public put<T, B = Record<string, any>>(url: string, data?: B, config?: FetchOptions): Promise<FetchResponse<T>> {
    return this.api.raw<T>(url, this.getConfig('PUT', config, data || {}));
  }

  public patch<T, B = Record<string, any>>(url: string, data?: B, config?: FetchOptions): Promise<FetchResponse<T>> {
    return this.api.raw<T>(url, this.getConfig('PATCH', config, data || {}));
  }
}

export function createApi(config: FetchOptions, headers: LooseObject): Api {
  return new Api(config, headers);
}
