import { default as _5_45month_45cart_45offeruA2extMCiwMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/5-month-cart-offer.vue?macro=true";
import { default as _6_45month_45half_45price8nePVwM7w9Meta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/6-month-half-price.vue?macro=true";
import { default as about3yH5GKokY2Meta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/about.vue?macro=true";
import { default as billingzm5auXpB9eMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/account/billing.vue?macro=true";
import { default as indexq4OIgyYJWAMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/account/index.vue?macro=true";
import { default as profileZDco41jdPQMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/account/profile.vue?macro=true";
import { default as accountK3vsF1trAbMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/account.vue?macro=true";
import { default as authorizeyurWVZQ6wCMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/authorize.vue?macro=true";
import { default as bbc_45promoWj3CSLEXc6Meta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/bbc-promo.vue?macro=true";
import { default as broadband_45friends_45and_45familyUrEaQ9OXAaMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/broadband-friends-and-family.vue?macro=true";
import { default as chatBGru2TqdjNMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/chat.vue?macro=true";
import { default as compass_45embedliYv1QNBU1Meta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/compass-embed.vue?macro=true";
import { default as compassogkjn0oxJXMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/compass.vue?macro=true";
import { default as contactEqVf7kq8UsMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/contact.vue?macro=true";
import { default as coverageRy7ijdiHzUMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/coverage.vue?macro=true";
import { default as glimpxDRgOALseIMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/glimp.vue?macro=true";
import { default as hyperfibreoAxoxqNXgxMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/hyperfibre.vue?macro=true";
import { default as indexZ8uFIhfWybMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/index.vue?macro=true";
import { default as internet_45speed_45testwR1Ok6R4YUMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/internet-speed-test.vue?macro=true";
import { default as loginoWTYboXkTKMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/login.vue?macro=true";
import { default as logoutitZkBudIWhMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/logout.vue?macro=true";
import { default as mobile_45friends_45and_45familyTUZNhOmxsyMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/mobile-friends-and-family.vue?macro=true";
import { default as indexbSbP7S2cQJMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/index.vue?macro=true";
import { default as accountP1lFjAYyM9Meta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/account.vue?macro=true";
import { default as connection_45setupCEbIheAnYTMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/connection-setup.vue?macro=true";
import { default as indexrkLRgMhrWtMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/index.vue?macro=true";
import { default as order_45summaryilFRF1MUSzMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/order-summary.vue?macro=true";
import { default as select_45planG1fZO74cymMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/select-plan.vue?macro=true";
import { default as successbvnTKZESnVMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/success.vue?macro=true";
import { default as register6qFaputVa5Meta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register.vue?macro=true";
import { default as our_45plansG5VKnTPXbwMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/our-plans.vue?macro=true";
import { default as plans3DZLsG2bQkMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/plans.vue?macro=true";
import { default as privacyYQVKiQqzCUMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/privacy.vue?macro=true";
import { default as promotionsmzZrqQpASTMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/promotions.vue?macro=true";
import { default as refer_45a_45friends89NSYljLPMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/refer-a-friend.vue?macro=true";
import { default as _1ckDrmpnKcgMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/register/1.vue?macro=true";
import { default as _2j5w15pQFdAMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/register/2.vue?macro=true";
import { default as _3NcbNOBv5C9Meta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/register/3.vue?macro=true";
import { default as _4UvvQsBZn66Meta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/register/4.vue?macro=true";
import { default as _5AGJ5o5ycBiMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/register/5.vue?macro=true";
import { default as indexKtukO4N2qRMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/register/index.vue?macro=true";
import { default as successbWyUyKwlnuMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/register/success.vue?macro=true";
import { default as registerVGGgmuTS2EMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/register.vue?macro=true";
import { default as simply_45broadbandBIpyDzHMXRMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/simply-broadband.vue?macro=true";
import { default as studentcard_45exclusive_45offerbibiFtTEGfMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/studentcard-exclusive-offer.vue?macro=true";
import { default as sustainabilityYMyP0jsXXNMeta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/sustainability.vue?macro=true";
import { default as termsyJLRTKBBT5Meta } from "/codebuild/output/src3240338192/src/zeronet-web/pages/terms.vue?macro=true";
export default [
  {
    name: "5-month-cart-offer",
    path: "/5-month-cart-offer",
    meta: _5_45month_45cart_45offeruA2extMCiwMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/5-month-cart-offer.vue")
  },
  {
    name: "6-month-half-price",
    path: "/6-month-half-price",
    meta: _6_45month_45half_45price8nePVwM7w9Meta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/6-month-half-price.vue")
  },
  {
    name: "about",
    path: "/about",
    meta: about3yH5GKokY2Meta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/about.vue")
  },
  {
    name: accountK3vsF1trAbMeta?.name,
    path: "/account",
    meta: accountK3vsF1trAbMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/account.vue"),
    children: [
  {
    name: "account-billing",
    path: "billing",
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/account/billing.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/account/index.vue")
  },
  {
    name: "account-profile",
    path: "profile",
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/account/profile.vue")
  }
]
  },
  {
    name: "authorize",
    path: "/authorize",
    meta: authorizeyurWVZQ6wCMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/authorize.vue")
  },
  {
    name: "bbc-promo",
    path: "/bbc-promo",
    meta: bbc_45promoWj3CSLEXc6Meta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/bbc-promo.vue")
  },
  {
    name: "broadband-friends-and-family",
    path: "/broadband-friends-and-family",
    meta: broadband_45friends_45and_45familyUrEaQ9OXAaMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/broadband-friends-and-family.vue")
  },
  {
    name: "chat",
    path: "/chat",
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/chat.vue")
  },
  {
    name: "compass-embed",
    path: "/compass-embed",
    meta: compass_45embedliYv1QNBU1Meta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/compass-embed.vue")
  },
  {
    name: "compass",
    path: "/compass",
    meta: compassogkjn0oxJXMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/compass.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactEqVf7kq8UsMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/contact.vue")
  },
  {
    name: "coverage",
    path: "/coverage",
    meta: coverageRy7ijdiHzUMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/coverage.vue")
  },
  {
    name: "glimp",
    path: "/glimp",
    meta: glimpxDRgOALseIMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/glimp.vue")
  },
  {
    name: "hyperfibre",
    path: "/hyperfibre",
    meta: hyperfibreoAxoxqNXgxMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/hyperfibre.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexZ8uFIhfWybMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/index.vue")
  },
  {
    name: "internet-speed-test",
    path: "/internet-speed-test",
    meta: internet_45speed_45testwR1Ok6R4YUMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/internet-speed-test.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginoWTYboXkTKMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutitZkBudIWhMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/logout.vue")
  },
  {
    name: "mobile-friends-and-family",
    path: "/mobile-friends-and-family",
    meta: mobile_45friends_45and_45familyTUZNhOmxsyMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/mobile-friends-and-family.vue")
  },
  {
    name: "mobile",
    path: "/mobile",
    meta: indexbSbP7S2cQJMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/index.vue")
  },
  {
    name: register6qFaputVa5Meta?.name,
    path: "/mobile/register",
    meta: register6qFaputVa5Meta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register.vue"),
    children: [
  {
    name: "mobile-register-account",
    path: "account",
    meta: accountP1lFjAYyM9Meta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/account.vue")
  },
  {
    name: "mobile-register-connection-setup",
    path: "connection-setup",
    meta: connection_45setupCEbIheAnYTMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/connection-setup.vue")
  },
  {
    name: "mobile-register",
    path: "",
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/index.vue")
  },
  {
    name: "mobile-register-order-summary",
    path: "order-summary",
    meta: order_45summaryilFRF1MUSzMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/order-summary.vue")
  },
  {
    name: "mobile-register-select-plan",
    path: "select-plan",
    meta: select_45planG1fZO74cymMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/select-plan.vue")
  },
  {
    name: "mobile-register-success",
    path: "success",
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/mobile/register/success.vue")
  }
]
  },
  {
    name: "our-plans",
    path: "/our-plans",
    meta: our_45plansG5VKnTPXbwMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/our-plans.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: plans3DZLsG2bQkMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/plans.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyYQVKiQqzCUMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/privacy.vue")
  },
  {
    name: "promotions",
    path: "/promotions",
    meta: promotionsmzZrqQpASTMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/promotions.vue")
  },
  {
    name: "refer-a-friend",
    path: "/refer-a-friend",
    meta: refer_45a_45friends89NSYljLPMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/refer-a-friend.vue")
  },
  {
    name: registerVGGgmuTS2EMeta?.name,
    path: "/register",
    meta: registerVGGgmuTS2EMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/register.vue"),
    children: [
  {
    name: "register-1",
    path: "1",
    meta: _1ckDrmpnKcgMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/register/1.vue")
  },
  {
    name: "register-2",
    path: "2",
    meta: _2j5w15pQFdAMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/register/2.vue")
  },
  {
    name: "register-3",
    path: "3",
    meta: _3NcbNOBv5C9Meta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/register/3.vue")
  },
  {
    name: "register-4",
    path: "4",
    meta: _4UvvQsBZn66Meta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/register/4.vue")
  },
  {
    name: "register-5",
    path: "5",
    meta: _5AGJ5o5ycBiMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/register/5.vue")
  },
  {
    name: "register",
    path: "",
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/register/index.vue")
  },
  {
    name: "register-success",
    path: "success",
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/register/success.vue")
  }
]
  },
  {
    name: "simply-broadband",
    path: "/simply-broadband",
    meta: simply_45broadbandBIpyDzHMXRMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/simply-broadband.vue")
  },
  {
    name: "studentcard-exclusive-offer",
    path: "/studentcard-exclusive-offer",
    meta: studentcard_45exclusive_45offerbibiFtTEGfMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/studentcard-exclusive-offer.vue")
  },
  {
    name: "sustainability",
    path: "/sustainability",
    meta: sustainabilityYMyP0jsXXNMeta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/sustainability.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsyJLRTKBBT5Meta || {},
    component: () => import("/codebuild/output/src3240338192/src/zeronet-web/pages/terms.vue")
  }
]