export const BREAKPOINTS = {
  tiny: 480,
  tablet: 768,
  desktop: 1024,
  widescreen: 1200,
  fullhd: 1680,
};

export const BBC_PROMO_CODE = 'bbc';

export const FNF_PROMO_CODE = 'fnf';
